import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    LinearProgress,
    OutlinedInput,
    Switch,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import { useNavigate } from "react-router-dom";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import { Context } from "../../context/setting";
import { Api } from "../../apis";

const DetailConfirrm = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const [pdfList, setPdfList] = useState<string[]>(contextStore?.pdfList || []);
    const [activeList, setactiveList] = useState("pdf");
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    useEffect(() => {
        if (contextStore?.source_file) {
            handleUpload(contextStore?.source_file);
        }
    }, []);

    const handleUpload = (file: any) => {
        const formData = new FormData();
        if (contextStore?.source_file?.type === "application/pdf") {
            formData.append("pdf_file", file);
            Api?.uploadPdf(formData)?.then((res: any) => {
                if (res?.status === 202) {
                    handleFetchPdf();
                    setContext({
                        ...contextStore,
                        source_file: undefined,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "PDF Uploaded Successful",
                        },
                    });
                }
            });
        }
        if (
            ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"]?.includes(
                contextStore?.source_file?.type
            ) ||
            contextStore?.source_filea?.name?.endsWith(".xlsx") ||
            contextStore?.source_filea?.name?.endsWith(".xls")
        ) {
            formData.append("file", file);
            formData.append("user_id", contextStore?.profile?.userId);
            Api?.uploadESGexcel(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    handleFetchExcel();
                    setContext({
                        ...contextStore,
                        source_file: undefined,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "PDF Uploaded Successful",
                        },
                    });
                }
            });
        }
    };

    const handleFetchPdf = () => {
        Api?.getPdfs({ user_id: contextStore?.profile?.userId })?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pdfList: res?.data?.namespaces?.sort() });
                setPdfList(res?.data?.namespaces?.sort());
            }
            setLoading(false);
        });
    };

    const handleFetchExcel = () => {
        Api?.getTables()?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pdfList: res?.data?.sort() });
                setPdfList(res?.data?.sort());
            }
            setLoading(false);
        });
    };

    const styles = () => {
        if (smd) {
            return {
                title: {
                    pl: 5,
                    gap: 1,
                },
                detailMain1: {
                    flexDirection: "column",
                    gap: 1,
                },
                detailMain2: {
                    width: "100%",
                },
                footerButton: {
                    mt: 1,
                    alignSelf: "center",
                },
            };
        }
        return {};
    };

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, ...styles()?.title }}>
                <IconButton onClick={() => navigate("/data_source/add_source")}>
                    <LessThanIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600}>
                    Add Data Source
                </Typography>
            </Box>
            <Box sx={{ display: "flex", px: 1.5, gap: 8, height: "100%", ...styles()?.detailMain1 }}>
                <Box sx={{ width: "58%", ...styles()?.detailMain2 }}>
                    <Typography variant="subtitle2">Upload Documents</Typography>
                    <Box
                        sx={{
                            height: "6rem",
                            border: "1px dashed #007BFF",
                            borderRadius: "14px",
                            mt: 3,
                            display: "flex",
                            alignItems: "center",
                            pl: 5,
                            cursor: "pointer",
                        }}
                        component={"label"}
                        onDrop={(e) => {
                            e.preventDefault();
                            handleUpload(e?.dataTransfer?.files[0]);
                            setLoading(true);
                            handleFetchPdf();
                        }}
                        onDragOver={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        <input
                            hidden
                            type="file"
                            accept=".pdf, .xls, .xlsx, .csv"
                            onChange={(e: { target: { files: any } }) => {
                                // e?.target?.files[0] && navigate("/data_source/add_detail")
                                handleUpload(e?.target?.files[0]);
                            }}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                            <UploadBoxIcon width={38} height={42} />
                            <Box>
                                <Typography variant="subtitle2">
                                    Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                </Typography>
                                <Typography variant="caption">Supported .pdf .txt. csv. json. docx or xlsx</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {!smd && (
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 3 }}>
                                <Typography variant="subtitle2">Uploaded Documents</Typography>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography>Pdf</Typography>
                                        <Switch
                                            size="small"
                                            color="default"
                                            value={activeList}
                                            onChange={(e: { target: { checked: boolean } }) => {
                                                if (e?.target?.checked) {
                                                    setPdfList(contextStore?.excelList);
                                                    setactiveList("excel");
                                                } else {
                                                    setPdfList(contextStore?.pdfList);
                                                    setactiveList("pdf");
                                                }
                                            }}
                                            sx={{
                                                "& .MuiSwitch-thumb": { color: "#4d93ff" },
                                                "& .MuiSwitch-track": { background: "#e8e8e8" },
                                            }}
                                        />
                                        <Typography>Excel</Typography>
                                    </Box>
                                    <Button
                                        sx={{ textTransform: "none", height: "2.3rem" }}
                                        onClick={() => {
                                            setLoading(true);
                                            if (activeList === "pdf") {
                                                handleFetchPdf();
                                            }
                                            if (activeList === "excel") {
                                                handleFetchExcel();
                                            }
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                style={{ width: "12px", height: "12px" }}
                                            />
                                        ) : (
                                            "refresh"
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ height: "51vh", overflowY: "auto" }}>
                                {pdfList?.map((item: string) => (
                                    <>
                                        <Box
                                            sx={{
                                                px: 1,
                                                py: 2,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography variant="caption">{item}</Typography>
                                            <DeleteIcon color={"#CC5555"} />
                                        </Box>
                                        <Box sx={{ px: 1, py: 0.5 }}>
                                            <Divider sx={{ borderColor: "#F3F3F4" }} />
                                        </Box>
                                    </>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        width: "35%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        ...styles()?.detailMain2,
                    }}
                >
                    <Box>
                        <Typography variant="subtitle2">Dataset Name</Typography>
                        <OutlinedInput
                            sx={{
                                width: "100%",
                                mt: 3,
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                    borderColor: "#CFCFCF",
                                    overflow: "hidden",
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "0.5rem",
                                    // padding: "14px 12px",
                                    fontSize: "0.9rem",
                                    color: "#C7C7C7",
                                },
                            }}
                            placeholder="Enter Dataset name"
                        />
                        <Typography variant="subtitle2" sx={{ mt: 3 }}>
                            Description (Optional)
                        </Typography>
                        <OutlinedInput
                            multiline
                            rows={4}
                            sx={{
                                width: "100%",
                                mt: 3,
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                    borderColor: "#CFCFCF",
                                    overflow: "hidden",
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "0.5rem",
                                    // padding: "14px 12px",
                                    fontSize: "0.9rem",
                                    color: "#C7C7C7",
                                },
                            }}
                        />
                    </Box>
                    {smd && (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mt: 3,
                                    borderWidth: "1px 1px 0px 1px",
                                    borderRadius: "10px 10px 0px 0px",
                                    borderStyle: "solid",
                                    borderColor: "#f2f2f2",
                                    p: 1,
                                }}
                            >
                                <Typography variant="subtitle2">Uploaded Documents</Typography>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography>Pdf</Typography>
                                        <Switch
                                            size="small"
                                            color="default"
                                            value={activeList}
                                            onChange={(e: { target: { checked: boolean } }) => {
                                                if (e?.target?.checked) {
                                                    setPdfList(contextStore?.excelList);
                                                    setactiveList("excel");
                                                } else {
                                                    setPdfList(contextStore?.pdfList);
                                                    setactiveList("pdf");
                                                }
                                            }}
                                            sx={{
                                                "& .MuiSwitch-thumb": { color: "#4d93ff" },
                                                "& .MuiSwitch-track": { background: "#e8e8e8" },
                                            }}
                                        />
                                        <Typography>Excel</Typography>
                                    </Box>
                                    <Button
                                        sx={{ textTransform: "none", height: "2.3rem" }}
                                        onClick={() => {
                                            setLoading(true);
                                            if (activeList === "pdf") {
                                                handleFetchPdf();
                                            }
                                            if (activeList === "excel") {
                                                handleFetchExcel();
                                            }
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                style={{ width: "12px", height: "12px" }}
                                            />
                                        ) : (
                                            "refresh"
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    height: "51vh",
                                    overflowY: "auto",
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "10px",
                                }}
                            >
                                {pdfList?.map((item: string) => (
                                    <>
                                        <Box
                                            sx={{
                                                px: 1,
                                                py: 2,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography variant="caption">{item}</Typography>
                                            <DeleteIcon color={"#CC5555"} />
                                        </Box>
                                        <Box sx={{ px: 1, py: 0.5 }}>
                                            <Divider sx={{ borderColor: "#F3F3F4" }} />
                                        </Box>
                                    </>
                                ))}
                            </Box>
                        </>
                    )}
                    <Button
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            "&:hover": { background: "#007BFFa3" },
                            width: "12rem",
                            alignSelf: "flex-end",
                            ...styles()?.footerButton,
                        }}
                        onClick={() => navigate("/bond_llm")}
                    >
                        Save Data Source
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DetailConfirrm;
