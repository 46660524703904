import { Box, Button, Icon, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "../InputField";
import axios from "axios";
import Loader from "../Loader";
import CopyIcon from "../../../components/Common/svgLogs/CopyIcon";
import DownloadIconLogo from "../../../components/Common/svgLogs/DownloadIconLogo";
import { Context } from "../../../context/setting";
import { useLocation } from "react-router-dom";
import { Api } from "../../../apis";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import ChatBox from "./ChatBox";
import PdfSection from "./PdfSection";

const MedicalSummarizer = () => {
    const location = useLocation();
    const containerRef: any = useRef<any>();
    const [details, setdetails] = useState<any>({});
    const [pdfModal, setPdfModal] = useState<boolean>(false);
    const [files, setFiles] = useState<any[]>([]);
    const [msgList, setMsgList] = useState<any[]>([
        {
            in: true,
            init: true,
            load: true,
            element:
                "To inquire about a query, please provide the Patient ID or registered mobile number associated with it.",
        },
    ]);

    useEffect(() => {
        if (location?.state) {
            setdetails(location?.state);
            handleDetail(location?.state?.task_id);
        }
    }, [location]);

    let timeout: NodeJS.Timeout | undefined;
    const handleDetail = (task_id: string) => {
        clearTimeout(timeout);
        Api?.checkInitStatus(task_id)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (res?.data?.response?.status === "running") {
                        setTimeout(() => {
                            handleDetail(task_id);
                        }, 3000);
                    } else if (res?.data?.response?.status === "completed") {
                        setFiles(res?.data?.response?.matched_files);
                        setMsgList((prev) => {
                            let result = [...prev];
                            result[0].load = false;
                            result[0].error = false;
                            result[0].element = res?.data?.response?.result?.sources[0]["content"];
                            return result;
                        });
                    }
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 10);
                } else {
                    setMsgList((prev) => {
                        let result = [...prev];
                        result[0].load = false;
                        result[0].error = true;
                        result[0].element = "Failed to fetch details";
                        return result;
                    });
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 10);
                }
            })
            ?.catch((err) => {
                setMsgList((prev) => {
                    let result = [...prev];
                    result[0].load = false;
                    result[0].error = true;
                    result[0].element = "Failed to fetch details";
                    return result;
                });
            });
    };

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={1} minSize={400}>
                <ChatBox
                    setPdfModal={setPdfModal}
                    details={details}
                    msgList={msgList}
                    setMsgList={setMsgList}
                    containerRef={containerRef}
                    pdfModal={pdfModal}
                    files={files}
                />
            </ReflexElement>
            {pdfModal && (
                <ReflexSplitter style={{ border: "none" }}>
                    <Box
                        sx={{
                            width: "16px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderWidth: "0px 2px 0px 2px",
                            borderStyle: "solid",
                            borderColor: "#E3F4FF",
                        }}
                    >
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                    </Box>
                </ReflexSplitter>
            )}
            {pdfModal && (
                <ReflexElement minSize={400} size={500}>
                    <PdfSection setPdfModal={setPdfModal} files={files} />
                </ReflexElement>
            )}
        </ReflexContainer>
    );
};

export default MedicalSummarizer;
