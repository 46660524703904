import { ArrowBack } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Context } from "../../../context/setting";
import { useContext, useState } from "react";
import ListItemUpload from "./ListItemUpload";
import QuestionForm from "./QuestionForm";
import Submission from "../../../components/Common/svgLogs/Submission";
import { useNavigate } from "react-router-dom";

const ImageUploadedConfirmationScreen = ({
    imageData,
    handleCapture,
    imageDetails,
    imageStatus,
}: {
    imageData: any[];
    handleCapture: any;
    imageDetails: any;
    imageStatus: any;
}) => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext(Context);
    const [step, setStep] = useState<string>("0");

    const getValueForTaskId = (taskId: any) => {
        return imageStatus[taskId];
    };

    const getCompletedImages = () => {
        const count = Object.values(imageStatus)?.filter((status: any) => status?.status === "completed").length;
        return count;
    };

    return (
        <Stack
            sx={{
                width: "100vw",
                height: "100vh",
            }}
        >
            <Stack direction={"row"} px={0.5} py={1} sx={{ alignItems: "center" }}>
                <IconButton sx={{ width: "2rem", height: "2rem" }} onClick={() => handleCapture()}>
                    <ArrowBack />
                </IconButton>
                <Typography color={"#007BFF"} sx={{ flex: 1, textAlign: "center", fontSize: "1.1rem" }}>
                    AI-Inspection
                </Typography>
            </Stack>
            {step === "0" && (
                <>
                    <Stack sx={{ background: "#F3FAFF", px: 1 }} flex={1}>
                        <Stack
                            direction={"row"}
                            px={0.5}
                            py={1}
                            sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
                        >
                            <Typography>Van 28</Typography>
                            <Typography>Completed {`${getCompletedImages()}`}/8</Typography>
                        </Stack>
                        <Typography>{contextStore?.profile?.userId}</Typography>
                        <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", gap: 1, pr: 1 }}>
                            {imageDetails?.map((img: any, index: number) => (
                                <ListItemUpload
                                    img={img}
                                    index={index}
                                    handleCapture={handleCapture}
                                    imageStatus={getValueForTaskId(img?.taskID)}
                                />
                            ))}
                        </Stack>
                    </Stack>
                    <Stack px={0.5} py={1} sx={{ background: "#F3FAFF" }}>
                        <Button
                            sx={{ borderRadius: "19px", fontSize: "1.1rem" }}
                            variant="contained"
                            onClick={() => setStep("1")}
                        >
                            Continue
                        </Button>
                    </Stack>
                </>
            )}
            {step === "1" && <QuestionForm setStep={setStep} reportNumber={imageDetails?.[0]?.reportNumber} />}
            {step === "2" && (
                <>
                    <Stack sx={{ background: "#F3FAFF", px: 1 }} flex={1}>
                        <Stack
                            direction={"row"}
                            px={0.5}
                            py={1}
                            sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
                        >
                            <Typography>Van 28</Typography>
                        </Stack>
                        <Typography>{contextStore?.profile?.userId}</Typography>
                        <Stack sx={{ alignItems: "center", justifyContent: "center", flex: 1, gap: 1 }}>
                            <Submission />
                            <Stack>
                                <Typography sx={{ textAlign: "center" }} variant="caption">
                                    Thank You
                                </Typography>
                                <Typography sx={{ textAlign: "center" }} variant="caption">
                                    for submitting your response.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack px={0.5} py={1} sx={{ background: "#F3FAFF" }}>
                        <Button
                            sx={{ borderRadius: "19px", fontSize: "1.1rem" }}
                            variant="contained"
                            // onClick={() => navigate("/report_detail_1")}
                        >
                            You can now close this window
                        </Button>
                        <Button
                            sx={{
                                borderRadius: "19px",
                                fontSize: "1.1rem",
                                mt: "10px",
                                background: "none",
                                color: "#007BFF",
                            }}
                            onClick={() => navigate("/landingWebcam")}
                        >
                            Inspect another vehicle
                        </Button>
                    </Stack>
                </>
            )}
        </Stack>
    );
};

export default ImageUploadedConfirmationScreen;
