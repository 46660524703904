import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import DamageReports from "../DamageReports/DamageReports";
import { ChevronLeftSharp } from "@mui/icons-material";

const ReportDetail2 = ({ data, setReport }: any) => {
    const [selectedReport, setSelectedReport] = useState<number>(0);
    const handleIndexText = (index: number) => {
        switch (index) {
            case 0:
                return "DRIVER SIDE";
            case 1:
                return "FRONT-RIGHT";
            case 2:
                return "FRONT";
            case 3:
                return "FRONT-LEFT";
            case 4:
                return "PASSENGER SIDE";
            case 5:
                return "REAR-RIGHT";
            case 6:
                return "REAR";
            case 7:
                return "REAR-LEFT";
            default:
                return "DRIVER SIDE";
        }
    };

    return (
        <Stack gap={2} sx={{ height: "100vh", width: "100%", overflowY: "auto" }}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ background: "rgba(217, 217, 217, 0.33)", py: 2, px: 6 }}
            >
                <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
                    <Button onClick={() => setReport(null)}>
                        <ChevronLeftSharp />
                    </Button>
                    <Typography color={"#007BFF"}>RIVIAN VAN</Typography>
                    <Typography>VIN NO 3C6URVJG6LE141044 </Typography>
                </Stack>
                <svg width="144" height="20" viewBox="0 0 144 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M102.897 8.98631C103.535 7.60546 104.693 6.91504 106.371 6.91504V8.94176C105.421 8.89722 104.604 9.14963 103.921 9.699C103.238 10.2484 102.897 11.1318 102.897 12.3493V18.2513H100.959V7.11548H102.897V8.98631Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M96.9559 16.8455C95.8126 17.9739 94.4243 18.5381 92.791 18.5381C91.1578 18.5381 89.7695 17.9739 88.6262 16.8455C87.4978 15.7171 86.9336 14.3288 86.9336 12.6807C86.9336 11.0326 87.4978 9.64432 88.6262 8.51589C89.7695 7.38746 91.1578 6.82324 92.791 6.82324C94.4243 6.82324 95.8126 7.38746 96.9559 8.51589C98.0991 9.64432 98.6708 11.0326 98.6708 12.6807C98.6708 14.3288 98.0991 15.7171 96.9559 16.8455ZM92.791 16.6451C93.9046 16.6451 94.84 16.2664 95.5973 15.5092C96.3545 14.752 96.7331 13.8091 96.7331 12.6807C96.7331 11.5523 96.3545 10.6094 95.5973 9.85219C94.84 9.09495 93.9046 8.71633 92.791 8.71633C91.6923 8.71633 90.7643 9.09495 90.0071 9.85219C89.2498 10.6094 88.8712 11.5523 88.8712 12.6807C88.8712 13.8091 89.2498 14.752 90.0071 15.5092C90.7643 16.2664 91.6923 16.6451 92.791 16.6451Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M79.3315 10.1195C79.3315 10.55 79.5468 10.899 79.9774 11.1662C80.408 11.4186 80.9276 11.6265 81.5364 11.7898C82.1452 11.9383 82.7539 12.1165 83.3627 12.3243C83.9714 12.5174 84.4911 12.8589 84.9217 13.3488C85.3523 13.824 85.5676 14.4402 85.5676 15.1974C85.5676 16.207 85.1741 17.0162 84.3872 17.625C83.6151 18.2338 82.6203 18.5381 81.4028 18.5381C80.3189 18.5381 79.3909 18.3006 78.6188 17.8255C77.8467 17.3503 77.2974 16.7193 76.9707 15.9324L78.6411 14.9747C78.8193 15.5092 79.1533 15.9324 79.6433 16.2442C80.1333 16.556 80.7198 16.7119 81.4028 16.7119C82.0412 16.7119 82.5683 16.5931 82.9841 16.3555C83.3998 16.1031 83.6077 15.7171 83.6077 15.1974C83.6077 14.7668 83.3924 14.4253 82.9618 14.1729C82.5312 13.9056 82.0115 13.6978 81.4028 13.5493C80.794 13.386 80.1852 13.2004 79.5765 12.9925C78.9677 12.7846 78.4481 12.4431 78.0175 11.968C77.5869 11.4929 77.3716 10.8841 77.3716 10.1417C77.3716 9.17662 77.7428 8.38226 78.4852 7.75865C79.2424 7.13505 80.1853 6.82324 81.3137 6.82324C82.2194 6.82324 83.0212 7.03111 83.719 7.44685C84.4317 7.84774 84.9662 8.41196 85.3226 9.1395L83.6968 10.0526C83.2959 9.10238 82.5015 8.62725 81.3137 8.62725C80.7643 8.62725 80.2966 8.76088 79.9106 9.02814C79.5245 9.28055 79.3315 9.64432 79.3315 10.1195Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M70.6374 6.82324C71.9441 6.82324 72.9908 7.23898 73.7778 8.07046C74.5647 8.88708 74.9582 10.0007 74.9582 11.4112V18.2486H73.0205V11.5226C73.0205 10.6169 72.7755 9.91901 72.2856 9.42903C71.7956 8.9242 71.12 8.67179 70.2588 8.67179C69.2937 8.67179 68.5142 8.97617 67.9203 9.58493C67.3264 10.1788 67.0294 11.0994 67.0294 12.3466V18.2486H65.0918V7.11277H67.0294V8.71633C67.8164 7.45427 69.019 6.82324 70.6374 6.82324Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M53.4666 13.5716C53.6596 14.5664 54.1124 15.3384 54.8251 15.8878C55.5527 16.4372 56.4435 16.7119 57.4977 16.7119C58.9677 16.7119 60.0367 16.1699 60.7049 15.086L62.353 16.0214C61.2691 17.6992 59.6358 18.5381 57.4532 18.5381C55.6863 18.5381 54.2461 17.9888 53.1325 16.89C52.0337 15.7765 51.4844 14.3733 51.4844 12.6807C51.4844 11.0029 52.0263 9.6072 53.1102 8.49362C54.1941 7.38003 55.5972 6.82324 57.3196 6.82324C58.9528 6.82324 60.2817 7.40231 61.3062 8.56043C62.3455 9.70371 62.8652 11.0846 62.8652 12.703C62.8652 12.9851 62.8429 13.2746 62.7984 13.5716H53.4666ZM57.3196 8.64952C56.2802 8.64952 55.419 8.94648 54.736 9.54039C54.053 10.1195 53.6299 10.899 53.4666 11.8789H60.9053C60.742 10.8247 60.3262 10.0229 59.6581 9.47357C58.9899 8.9242 58.2104 8.64952 57.3196 8.64952Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M43.8823 10.1195C43.8823 10.55 44.0976 10.899 44.5282 11.1662C44.9587 11.4186 45.4784 11.6265 46.0872 11.7898C46.6959 11.9383 47.3047 12.1165 47.9135 12.3243C48.5222 12.5174 49.0419 12.8589 49.4725 13.3488C49.9031 13.824 50.1184 14.4402 50.1184 15.1974C50.1184 16.207 49.7249 17.0162 48.938 17.625C48.1659 18.2338 47.1711 18.5381 45.9535 18.5381C44.8697 18.5381 43.9417 18.3006 43.1696 17.8255C42.3975 17.3503 41.8481 16.7193 41.5215 15.9324L43.1919 14.9747C43.37 15.5092 43.7041 15.9324 44.1941 16.2442C44.6841 16.556 45.2705 16.7119 45.9535 16.7119C46.592 16.7119 47.1191 16.5931 47.5348 16.3555C47.9506 16.1031 48.1584 15.7171 48.1584 15.1974C48.1584 14.7668 47.9432 14.4253 47.5126 14.1729C47.082 13.9056 46.5623 13.6978 45.9535 13.5493C45.3448 13.386 44.736 13.2004 44.1273 12.9925C43.5185 12.7846 42.9988 12.4431 42.5683 11.968C42.1377 11.4929 41.9224 10.8841 41.9224 10.1417C41.9224 9.17662 42.2936 8.38226 43.036 7.75865C43.7932 7.13505 44.736 6.82324 45.8645 6.82324C46.7702 6.82324 47.572 7.03111 48.2698 7.44685C48.9825 7.84774 49.517 8.41196 49.8734 9.1395L48.2475 10.0526C47.8466 9.10238 47.0523 8.62725 45.8645 8.62725C45.3151 8.62725 44.8474 8.76088 44.4613 9.02814C44.0753 9.28055 43.8823 9.64432 43.8823 10.1195Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M40.4582 8.98691H37.4961V15.0225C37.4961 15.5719 37.6 15.9654 37.8079 16.2029C38.0306 16.4256 38.3646 16.5444 38.8101 16.5593C39.2555 16.5593 39.8049 16.5444 40.4582 16.5147V18.2519C38.7655 18.4746 37.5257 18.341 36.7388 17.851C35.9519 17.3462 35.5584 16.4034 35.5584 15.0225V8.98691H33.3535V7.11608H35.5584V4.57711L37.4961 3.99805V7.11608H40.4582V8.98691Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M32.5147 4.24088C30.5548 4.07756 29.5748 4.92388 29.5748 6.77985V7.11393H32.5147V8.98475H29.5748V18.2498H27.6372V8.98475H25.8555V7.11393H27.6372V6.77985C27.6372 5.28023 28.0529 4.14437 28.8844 3.37229C29.7307 2.6002 30.9408 2.26613 32.5147 2.37006V4.24088Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M23.2019 16.8455C22.0587 17.9739 20.6704 18.5381 19.0371 18.5381C17.4039 18.5381 16.0156 17.9739 14.8723 16.8455C13.7439 15.7171 13.1797 14.3288 13.1797 12.6807C13.1797 11.0326 13.7439 9.64432 14.8723 8.51589C16.0156 7.38746 17.4039 6.82324 19.0371 6.82324C20.6704 6.82324 22.0587 7.38746 23.2019 8.51589C24.3452 9.64432 24.9169 11.0326 24.9169 12.6807C24.9169 14.3288 24.3452 15.7171 23.2019 16.8455ZM19.0371 16.6451C20.1507 16.6451 21.0861 16.2664 21.8434 15.5092C22.6006 14.752 22.9792 13.8091 22.9792 12.6807C22.9792 11.5523 22.6006 10.6094 21.8434 9.85219C21.0861 9.09495 20.1507 8.71633 19.0371 8.71633C17.9384 8.71633 17.0104 9.09495 16.2532 9.85219C15.4959 10.6094 15.1173 11.5523 15.1173 12.6807C15.1173 13.8091 15.4959 14.752 16.2532 15.5092C17.0104 16.2664 17.9384 16.6451 19.0371 16.6451Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M5.902 18.5384C4.44691 18.5384 3.1997 18.1969 2.16035 17.5139C1.13586 16.8309 0.415738 15.8955 0 14.7077L1.78173 13.6832C2.39049 15.5688 3.77876 16.5117 5.94654 16.5117C7.01558 16.5117 7.83221 16.2964 8.39643 15.8658C8.96064 15.4203 9.24275 14.8339 9.24275 14.1063C9.24275 13.3639 8.96064 12.8071 8.39643 12.4359C7.83221 12.0647 6.88937 11.6713 5.56792 11.2555C4.91462 11.0477 4.41722 10.8843 4.07572 10.7656C3.73422 10.6319 3.30363 10.4389 2.78396 10.1865C2.27914 9.91924 1.90052 9.65198 1.6481 9.38472C1.39569 9.10261 1.16555 8.73884 0.957682 8.29341C0.764661 7.84798 0.66815 7.34315 0.66815 6.77893C0.66815 5.42779 1.14328 4.35875 2.09354 3.57181C3.0438 2.77003 4.20192 2.36914 5.56792 2.36914C6.80029 2.36914 7.86933 2.68094 8.77504 3.30455C9.69561 3.92816 10.386 4.75221 10.8463 5.77671L9.10912 6.77893C8.44097 5.17537 7.26057 4.37359 5.56792 4.37359C4.7216 4.37359 4.0386 4.58146 3.51893 4.9972C2.99925 5.41294 2.73942 5.97715 2.73942 6.68985C2.73942 7.37285 2.98441 7.89252 3.47438 8.24887C3.96436 8.60521 4.81068 8.97641 6.01335 9.36245C6.44394 9.49608 6.74832 9.59259 6.92649 9.65198C7.10467 9.71137 7.37935 9.81531 7.75055 9.96379C8.13659 10.1123 8.4187 10.231 8.59687 10.3201C8.77504 10.4092 9.01261 10.5354 9.30956 10.6988C9.62137 10.8621 9.85151 11.018 9.99999 11.1665C10.1485 11.3149 10.3192 11.5005 10.5122 11.7232C10.7201 11.9311 10.8686 12.1464 10.9577 12.3691C11.0468 12.5918 11.121 12.8517 11.1804 13.1486C11.2546 13.4307 11.2917 13.7351 11.2917 14.0618C11.2917 15.4278 10.7943 16.5191 9.79954 17.3357C8.80474 18.1375 7.50556 18.5384 5.902 18.5384Z"
                        fill="#007BFF"
                    />
                    <path
                        d="M108.713 21.8276C112.678 18.3793 121.265 11.2414 121.816 10.9655L132.161 2H143.196L130.092 11.4828L119.245 20.7978C118.472 21.4623 117.485 21.8276 116.466 21.8276H108.713Z"
                        fill="#BEDFF1"
                    />
                    <path
                        d="M114.406 2C119.004 8.55172 128.268 21.7241 128.544 22H137.682L123.199 2H114.406Z"
                        fill="white"
                        stroke="url(#paint0_linear_10752_1691)"
                        stroke-width="2.13333"
                    />
                    <path
                        d="M143.202 2H132.167L121.133 11.5349L128.489 22H137.685L130.098 11.5349L143.202 2Z"
                        fill="#007BFF"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_10752_1691"
                            x1="122.337"
                            y1="14.2414"
                            x2="132.165"
                            y2="5.96552"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#3386D3" stop-opacity="0.35" />
                            <stop offset="0.0001" stop-color="#3386D3" stop-opacity="0.35" />
                            <stop offset="1" stop-color="#0085FF" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </Stack>
            <DamageReports setSelectedReport={setSelectedReport} data={data} selectedReport={selectedReport} />
            <Stack px={6} gap={3} sx={{ flex: "1 0 20vh" }}>
                <Stack gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>Damage Report</Typography>
                    <Stack>
                        <Typography
                            sx={{ color: "#484848", fontSize: "0.875rem" }}
                        >{`Category: ${data?.image_results?.[0]?.vehicle_model}`}</Typography>
                        {/* <Typography sx={{ color: "#484848", fontSize: "0.875rem" }}>
                            Confidence Score:{" "}
                            {(data?.image_results?.[selectedReport]?.part_avg_confidence * 100)?.toFixed(2)}%
                        </Typography> */}
                    </Stack>
                </Stack>
                {/* <Stack gap={2}>
                    <Typography sx={{ fontWeight: "bold" }}>Interior Damage</Typography>
                    <Typography sx={{ color: "#484848", fontSize: "0.875rem" }}>No Damage Detected</Typography>
                </Stack> */}
                <Stack gap={1}>
                    <Stack gap={1}>
                        <Typography sx={{ fontWeight: "bold" }}>Exterior Damage</Typography>
                        {data?.image_results?.map((item: any, index: number) => (
                            <Stack gap={3}>
                                <Stack justifyContent={"space-between"} direction={"row"}>
                                    <Typography sx={{ fontSize: "0.9rem" }}>
                                        {index + 1}. {handleIndexText(index)}
                                    </Typography>
                                    <Stack alignItems={"center"} direction={"row"} gap={2}>
                                        <Typography sx={{ fontSize: "0.9rem" }}>severity level</Typography>
                                        <Stack direction={"row"} gap={1}>
                                            {["#D9D9D9", "#BFB220", "#DB881A", "#E05243"]?.map((_) => (
                                                <div
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "50%",
                                                        background: _,
                                                    }}
                                                ></div>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack alignItems={"center"}>
                                    <img
                                        src={`${item?.modified_base64}`}
                                        alt="img"
                                        style={{ height: "58vh", borderRadius: "8px" }}
                                    />
                                </Stack>
                                {Object.values(item?.damage_report).length !== 0 && (
                                    <Stack px={4} mt={1} mb={1}>
                                        <Table sx={{ border: "1px solid #E3E3E3", borderRadius: "8px 8px 0px 0px" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        size="small"
                                                        sx={{
                                                            background: "#C0E0F1",
                                                            width: "10rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Severity
                                                    </TableCell>
                                                    <TableCell
                                                        size="small"
                                                        sx={{ background: "#C0E0F1", borderTopLeftRadius: "8px" }}
                                                    >
                                                        Component
                                                    </TableCell>
                                                    <TableCell size="small" sx={{ background: "#C0E0F1" }}>
                                                        Damage Type
                                                    </TableCell>
                                                    <TableCell size="small" sx={{ background: "#C0E0F1" }}>
                                                        Zoom IN
                                                    </TableCell>
                                                    <TableCell
                                                        size="small"
                                                        sx={{ background: "#C0E0F1", borderTopRightRadius: "8px" }}
                                                    >
                                                        Repair Method
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.values(item?.damage_report)?.map(
                                                    (damage: any, index: number) => {
                                                        return (
                                                            <TableRow sx={{ mt: "1vh" }}>
                                                                <TableCell
                                                                    size="small"
                                                                    sx={{
                                                                        borderBottom: "none",
                                                                        py: 2,
                                                                        px: 2,
                                                                    }}
                                                                >
                                                                    {damage?.severity === "Minor" && (
                                                                        <Box sx={{ display: "flex", gap: 2 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    background: "#C8B924",
                                                                                    // textAlign: "center",
                                                                                    width: "33px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            >
                                                                                {index + 1}
                                                                            </Box>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: "#C8B924",
                                                                                    textAlign: "center",
                                                                                    py: 0.5,
                                                                                }}
                                                                            >
                                                                                {damage?.severity}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                    {damage?.severity === "Medium" && (
                                                                        <Box sx={{ display: "flex", gap: 2 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    background: "#D98618",
                                                                                    // textAlign: "center",
                                                                                    width: "33px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            >
                                                                                {index + 1}
                                                                            </Box>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: "#D98618",
                                                                                    textAlign: "center",
                                                                                    py: 0.5,
                                                                                }}
                                                                            >
                                                                                {damage?.severity}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                    {damage?.severity === "Major" && (
                                                                        <Box sx={{ display: "flex", gap: 2 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    background: "#E05243",
                                                                                    // textAlign: "center",
                                                                                    width: "33px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            >
                                                                                {index + 1}
                                                                            </Box>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: "#E05243",
                                                                                    textAlign: "center",
                                                                                    py: 0.5,
                                                                                }}
                                                                            >
                                                                                {damage?.severity}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    size="small"
                                                                    sx={{ borderBottom: "none", color: "#4F8BFF" }}
                                                                >
                                                                    {damage?.component}
                                                                </TableCell>
                                                                <TableCell size="small" sx={{ borderBottom: "none" }}>
                                                                    {damage?.damage_type}
                                                                </TableCell>
                                                                <TableCell size="small" sx={{ borderBottom: "none" }}>
                                                                    {/* {damage?.zoomin_cropped_image_base64} */}
                                                                    <img
                                                                        src={`${damage?.zoomin_cropped_image_base64}`}
                                                                        alt="img"
                                                                        style={{
                                                                            height: "12vh",
                                                                            borderRadius: "8px",
                                                                            maxWidth: "12vh",
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell size="small" sx={{ borderBottom: "none" }}>
                                                                    {damage?.repair_method}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Stack>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                    <img />
                </Stack>
                <Stack px={4} gap={3} py={1}>
                    <Stack sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                        <Typography
                            sx={{
                                py: 0.7,
                                px: 4,
                                background:
                                    "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                            }}
                        >
                            LIGHTS
                        </Typography>
                        <Stack gap={1} py={1}>
                            {Object?.entries(data?.qa?.["LIGHTS"])?.map((item: any) => (
                                <Stack direction={"row"} px={4}>
                                    <Typography sx={{ flex: 1 }}>{item[0]}</Typography>
                                    <Typography sx={{ width: "30%" }}>{item[1]}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Stack sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                        <Typography
                            sx={{
                                py: 0.7,
                                px: 4,
                                background:
                                    "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                            }}
                        >
                            ELECTRICAL
                        </Typography>
                        {Object?.entries(data?.qa?.["ELECTRICAL"])?.map((item: any) => (
                            <Stack direction={"row"} px={4}>
                                <Typography sx={{ flex: 1 }}>{item[0]}</Typography>
                                <Typography sx={{ width: "30%" }}>{item[1]}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                        <Typography
                            sx={{
                                py: 0.7,
                                px: 4,
                                background:
                                    "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                            }}
                        >
                            DRIVER COCKPIT
                        </Typography>
                        {Object?.entries(data?.qa?.["DRIVER COCKPIT"])?.map((item) => (
                            <Stack direction={"row"} px={4}>
                                <Typography sx={{ flex: 1 }}>{item[0]}</Typography>
                                <Typography sx={{ width: "30%" }}>{`${item[1]}`}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                        <Typography
                            sx={{
                                py: 0.7,
                                px: 4,
                                background:
                                    "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                            }}
                        >
                            PASSENGER AREA
                        </Typography>
                        {Object?.entries(data?.qa?.["PASSENGER AREA"])?.map((item) => (
                            <Stack direction={"row"} px={4}>
                                <Typography sx={{ flex: 1 }}>{item[0]}</Typography>
                                <Typography sx={{ width: "30%" }}>{`${item[1]}`}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                        <Typography
                            sx={{
                                py: 0.7,
                                px: 4,
                                background:
                                    "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                            }}
                        >
                            ENGINE MECHANICAL
                        </Typography>
                        {Object?.entries(data?.qa?.["ENGINE MECHANICAL"])?.map((item) => (
                            <Stack direction={"row"} px={4}>
                                <Typography sx={{ flex: 1 }}>{item[0]}</Typography>
                                <Typography sx={{ width: "30%" }}>{`${item[1]}`}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ReportDetail2;
