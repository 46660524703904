import { Box } from "@mui/material";
import Stencil1 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil1";
import Stencil2 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil2";
import Stencil3 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil3";
import Stencil4 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil4";
import Stencil5 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil5";
import Stencil6 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil6";
import Stencil7 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil7";
import Stencil8 from "../../components/Common/svgLogs/DamageDetectionTest/Stencils/Stencil8";
import { useEffect, useState } from "react";

const Stencil = ({ imageIndex, detectionObj }: any) => {
    const [carDetected, setCarDetected] = useState<boolean>(false);

    // useEffect(() => {
    //     if (detectionObj?.length > 0) {
    //         let isFound = false;
    //         detectionObj.forEach((object: any) => {
    //             if (object["class"] === "car" && object["score"] >= 0.8) {
    //                 isFound = true;
    //                 setCarDetected(true);
    //             }
    //         });
    //         if (isFound === false) setCarDetected(false);
    //     }
    // }, [detectionObj]);

    //

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                zIndex: 2,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {imageIndex === 0 ? (
                <Stencil1 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 1 ? (
                <Stencil2 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 2 ? (
                <Stencil3 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 3 ? (
                <Stencil4 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 4 ? (
                <Stencil5 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 5 ? (
                <Stencil6 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 6 ? (
                <Stencil7 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : imageIndex === 7 ? (
                <Stencil8 stroke={carDetected ? "rgb(0, 255, 0)" : "#fff"} />
            ) : null}
        </Box>
    );
};

export default Stencil;

//
