import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Context } from "../../context/setting";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import PdfIcon from "../../components/Common/svgLogs/PdfIcon";
import LogoXIcon from "../../components/Common/svgLogs/LogoXIcon";
import ShortIcon from "../../components/Common/svgLogs/ShortIcon";
import MediumIcon from "../../components/Common/svgLogs/MediumIcon";
import NoLimitIcon from "../../components/Common/svgLogs/NoLimitIcon";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import "react-reflex/styles.css";
import PdfSocket from "./SummarySocket";
import LoadingDots from "../Chat/Effects/LoadingDots";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Banner from "../Landing_page/Banner";

const Summarize = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [banner, setBanner] = useState<boolean>(true);
    const [msg, socketHooks, msgRef] = PdfSocket();
    const [summary, setSummary] = useState<string>("");
    const [summarizeStatus, setSummarizeStatus] = useState<boolean>(false);
    const [pdfList, setPdfList] = useState<any[]>([]);
    const [selectedPdf, setSelectedPdf] = useState<any>({});
    const [summaryType, setSummaryType] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [showFullNames, setShowFullNames] = useState<boolean>(false);
    const [costBoxVisible, setCostBoxVisible] = useState(false);
    const [costBoxData, setCostBoxData] = useState({ total_cost: 0, time_taken: "0.02", model: "", prompt_tokens: "" });
    const [costVisible, setCostVisible] = useState<boolean>(false);
    const [promptToken, setpromptTokenVisible] = useState<boolean>(false);

    const icons: any = {
        PDF: <PdfIcon />,
    };

    // console.log({ selectedPdf });
    useEffect(() => {
        if (msgRef?.summary?.length > 0 && msgRef?.summary) {
            // console.log({ msgRef });
            if (msgRef?.summary?.startsWith("An error occurred:")) {
                setSummary(() => msgRef?.summary);
                setLoader(false);
            } else {
                const summary_text = JSON?.parse(`${msgRef?.summary}`) || {};
                setSummary(() => summary_text?.response || "NA");
                setCostBoxData(() => summary_text?.cost_analysis || {});
            }
        }
    }, [msgRef?.summary]);

    // console.log({msgRef});

    useEffect(() => {
        if (typeof msgRef?.load === "boolean" && !msgRef?.load) {
            setLoader(false);
        }
    }, [msgRef?.load]);

    useEffect(() => {
        if (contextStore?.pdfList?.length > 0) {
            setPdfList(contextStore?.pdfList);
        }
    }, [contextStore?.pdfList]);

    // console.log({ costBoxData });

    const handleSummaryJSON = (sum_type?: string) => {
        setLoader(true);
        const pdfName = selectedPdf?.file_id;
        const summaryName = sum_type || summaryType;
        if (selectedPdf?.file_name?.length > 0) {
            socketHooks?.sendJsonMessage({
                pdf_name: {
                    pdf_id: pdfName,
                    user_id: selectedPdf?.user_id,
                    // user_id: "ec0732c9-9e59-460d-b496-9830fd7d272b",
                },
                key_findings: {
                    pdf_name: pdfName,
                    persona: contextStore?.temparary?.persona || "Financial Analyst",
                },
            });
        }
    };

    // useEffect(() => {
    //     if (selectedPdf?.file_name?.length > 0) {
    //
    //     }
    // }, [selectedPdf?.file_name]);

    const data = [
        {
            text: "Summary",
            altText:
                "Distill lengthy documents into essential summaries with AI. Save time for researchers and knowledge workers while promoting efficient information sharing.",
            url: "https://d3fvaqnlz9wyiv.cloudfront.net/softsensorX/SUMMARIZE-DOCUMENTS.gif",
        },
    ];

    return (
        <ReflexContainer orientation="vertical">
            {banner && !sessionStorage.getItem("summarize") ? <Banner setBanner={setBanner} data={data} /> : null}
            <ReflexElement className="left-pane" flex={1} minSize={600}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4, position: "relative" }}>
                    <Box>
                        <Typography sx={{ fontSize: "24px", pl: 2, pt: 1 }}>Summarize Document</Typography>
                        <Typography variant="subtitle2" pl={2} pt={1} sx={{ fontSize: "14px", color: "#424F65" }}>
                            Select a Document to summarize
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: "60vh",
                            overflowY: "auto",
                            mr: 0.7,
                            // boxShadow: "inset 0px -9px 13px -5px rgb(83 124 204 / 12%)",
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {["", "Title", "Type"]?.map((elem, index) => (
                                        <TableCell
                                            key={elem + index}
                                            sx={{
                                                borderBottomWidth: "1px",
                                                background: "#fefdfd",
                                            }}
                                        >
                                            <Typography variant="caption">{elem}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pdfList?.map((item: any) => (
                                    <TableRow
                                        key={item?.file_id}
                                        onClick={() => setSelectedPdf(item)}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell>
                                            <Checkbox checked={selectedPdf?.file_id === item?.file_id} />
                                        </TableCell>

                                        {!showFullNames ? (
                                            <TableCell sx={{ pl: 3, minWidth: "24rem" }}>{item?.file_name}</TableCell>
                                        ) : (
                                            <TableCell sx={{ pl: 3, minWidth: "24rem" }}>
                                                <span title={item?.file_name}>{item?.file_name.slice(0, 10)}...</span>
                                            </TableCell>
                                        )}
                                        <TableCell sx={{ pl: 3 }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <> {icons["PDF"]} PDF </>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box
                        sx={{
                            // position: "absolute",
                            bottom: "12rem",
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: "none",
                                background: Object.keys(selectedPdf).length === 0 ? "#ccc" : "#007BFF",
                                color: "#fff",
                                "&:hover": {
                                    background: Object.keys(selectedPdf).length === 0 ? "#ccc" : "#007BFF",
                                    color: "#fff",
                                },
                                width: "215px",
                                height: "41px",
                                borderRadius: "6px",
                                cursor: Object.keys(selectedPdf).length === 0 ? "not-allowed" : "pointer",
                            }}
                            disabled={Object.keys(selectedPdf).length === 0}
                            onClick={() => {
                                handleSummaryJSON();
                                setSummarizeStatus(true);
                                setShowFullNames(true);
                            }}
                        >
                            Summarize
                        </Button>
                    </Box>
                </Box>
            </ReflexElement>

            {summarizeStatus && (
                <ReflexSplitter style={{ border: "none" }}>
                    <Box
                        sx={{
                            width: "16px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderWidth: "0px 2px 0px 2px",
                            borderStyle: "solid",
                            borderColor: "#E3F4FF",
                        }}
                    >
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                    </Box>
                </ReflexSplitter>
            )}
            {summarizeStatus && (
                <ReflexElement className="right-pane" flex={1} minSize={600}>
                    <Box sx={{ px: 3, pl: 4, display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                            }}
                        >
                            <Box sx={{ whiteSpace: "pre-line" }}>
                                <Typography sx={{ fontSize: "1.3rem", pt: 2 }}>{selectedPdf?.file_name}</Typography>
                                <Typography variant="subtitle2">Summary</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    // border: "1px solid red",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    // width: "20%",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setSummarizeStatus(false);
                                    setSelectedPdf({});
                                    setShowFullNames(false);
                                }}
                            >
                                <CloseIcon sx={{ color: "#007BFF" }} />
                                <Typography sx={{ ml: "4px", color: "#007BFF", fontSize: "14px" }}>Close</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                border: "1px solid #EEEEEE",
                                borderRadius: "10px",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{ p: 1.0 }}>
                                <LogoXIcon />
                                <Box
                                    sx={{
                                        height: "60vh",
                                        overflowY: "auto",
                                        p: 0.3,
                                        mt: 1,
                                    }}
                                >
                                    {loader ? (
                                        <LoadingDots />
                                    ) : (
                                        selectedPdf?.file_name?.length > 0 &&
                                        summary?.length > 0 &&
                                        summary?.split("\n\n")?.map((elem: string) => (
                                            <>
                                                {elem?.split("\n")?.map((item: string, index: number) => (
                                                    <Typography
                                                        sx={{
                                                            ...(index === 0
                                                                ? {
                                                                      fontWeight: 600,
                                                                      fontSize: "0.9rem",
                                                                      "&:after": { content: '" :"' },
                                                                  }
                                                                : {}),
                                                        }}
                                                    >
                                                        {item}
                                                    </Typography>
                                                ))}
                                                <br />
                                            </>
                                        ))
                                    )}
                                    {selectedPdf?.file_name?.length === 0 && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "70%",
                                            }}
                                        >
                                            Please Select A File To Generate Summary
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "120px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {summary?.length > 0 && (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "40px",
                                            border: "1px solid #EEE",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => setCostBoxVisible(!costBoxVisible)}
                                            sx={{
                                                width: "1.8rem",
                                                height: "1.8rem",
                                                color: costBoxVisible ? "#007BFF" : "#000",
                                                backgroundColor: costBoxVisible ? "#e3f4ff" : "transparent",
                                                "&:hover": {
                                                    backgroundColor: costBoxVisible ? "#e3f4ff" : "transparent",
                                                },
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {costBoxVisible && (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "75px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            px: 1.0,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "70%",
                                                height: "75px",
                                                display: "flex",
                                                alignItems: "center",
                                                px: 1.0,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "auto",
                                                    height: "80%",
                                                    // border: "1px solid red",
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    alignItems: "flex-start",
                                                    flexDirection: "column",
                                                    marginRight: "15px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ color: "#007BFF", fontSize: "14px", fontWeight: "400" }}
                                                >
                                                    {costBoxData?.model || " "}
                                                </Typography>
                                                <Typography sx={{ fontSize: "11px" }}>Model Used</Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "auto",
                                                    height: "80%",
                                                    // border: "1px solid red",
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    alignItems: "flex-start",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ color: "#007BFF", fontSize: "14px", fontWeight: "400" }}
                                                >
                                                    {parseFloat(costBoxData?.time_taken).toFixed(1) || " "} S
                                                </Typography>
                                                <Typography sx={{ fontSize: "11px" }}>Time Taken</Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "24%",
                                                height: "100%",
                                                py: 1,
                                                // px: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    width: "50%",
                                                    heigth: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ color: "#007BFF", fontSize: "14px", fontWeight: "400" }}
                                                >
                                                    Total Cost
                                                </Typography>
                                                <Box sx={{ display: "flex" }}>
                                                    {costVisible ? (
                                                        <Typography
                                                            sx={{
                                                                color: "black",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            $ {costBoxData?.total_cost.toFixed(2) || " "}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: "#ACACAC",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            Show
                                                        </Typography>
                                                    )}
                                                    {!costVisible ? (
                                                        <VisibilityIcon
                                                            onClick={() => setCostVisible(!costVisible)}
                                                            fontSize="small"
                                                            sx={{ color: "#ACACAC", cursor: "pointer" }}
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            onClick={() => setCostVisible(!costVisible)}
                                                            fontSize="small"
                                                            sx={{ color: "black", cursor: "pointer" }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    width: "50%",
                                                    heigth: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ color: "#007BFF", fontSize: "14px", fontWeight: "400" }}
                                                >
                                                    Prompt Token
                                                </Typography>
                                                <Box sx={{ display: "flex" }}>
                                                    {promptToken ? (
                                                        <Typography
                                                            sx={{
                                                                color: "black",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            {costBoxData?.prompt_tokens || " "}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                color: "#ACACAC",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            Show
                                                        </Typography>
                                                    )}

                                                    {!promptToken ? (
                                                        <VisibilityIcon
                                                            onClick={() => setpromptTokenVisible(!promptToken)}
                                                            fontSize="small"
                                                            sx={{ color: "#ACACAC", cursor: "pointer" }}
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            onClick={() => setpromptTokenVisible(!promptToken)}
                                                            fontSize="small"
                                                            sx={{ color: "black", cursor: "pointer" }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </ReflexElement>
            )}
        </ReflexContainer>
    );
};

export default Summarize;
