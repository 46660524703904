import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, redirect } from "react-router-dom";
import Fallback from "../components/Fallback";
import { Box } from "@mui/material";

import SideBar from "../screens/SideBar";
import Finance from "../screens/Finance";
import AppBar from "../screens/AppBar/AppBar";
import { makeStyles } from "@mui/styles";
import DataSource from "../screens/Data_Sources";
import AddSource from "../screens/Data_Sources/AddSource";
import DetailConfirrm from "../screens/Data_Sources/DetailConfirrm";
import ChatHistory from "../screens/ChatHistory";
import NewChat from "../screens/ChatHistory/NewChat";
import { Api } from "../apis";
import { Context } from "../context/setting";
import Summarize from "../screens/Summarize/Summarize";
import AddMongoSource from "../screens/Data_Sources/AddMongoSource";
import AddYoutubeSource from "../screens/Data_Sources/AddYoutubeSource";
import AddGoogleSheetsSource from "../screens/Data_Sources/AddGoogleSheetsSource";
import AddGoogleDocsSource from "../screens/Data_Sources/AddGoogleDocsSource";
import ViewMultiplePDF from "../screens/View_PDF/ViewMultiplePDF";
import Pathology from "../screens/Pathology";
import ReportGeneration from "../screens/Report_Generation";
import Login from "../screens/Auth/Login";
import PublicRoute from "./PublicRoute";
import RestrictedRoute from "./RestrictedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Calculator from "../screens/Calculator/Calculator";
import SeeReport from "../screens/Report_Generation/Generation/SeeReport";
import ChatBox from "../screens/Report_Generation/Variance/ChatBox";
import ClaimChatBox from "../screens/Claims/Chat/ChatBox";
import Environment from "../enviroment";
import Intro from "../screens/Sectors/Intro";
import Bot from "../screens/Sectors/Bot";
import AnamolyDetection from "../screens/AnamolyDetection/AnamolyDetection";
import RestaurantBot from "../screens/Restaurent_Bot/RestaurantBot";
import AlertGeneration from "../screens/Alert_Generation/AlertGen";
import Claims from "../screens/Claims/Claims";
// import UploadDetail from "../screens/Claims/Detail/UploadDetail";
import PageNotFound from "../screens/Auth/PageNotFound";
import OverViewPage from "../screens/Sectors/OverViewPage";
import Summary from "../screens/Claims/Summary/Summary";
import axios from "axios";
import DocumentLoadingModal from "../screens/Chat/DocumentLoading/DocumentLoadingModal";
import CreateProject from "../screens/Claims/NewProject/CreateProject";
import ViewProject from "../screens/Claims/ViewProject/ViewProject";
import References from "../screens/Claims/References/References";
import Comparison from "../screens/Claims/Comparison/Comparison";
import Tables from "../screens/Claims/Tables/Tables";
import Test from "../screens/Test";
import TrialBalance from "../screens/Finance/TrialBalance";
import Upload from "../screens/Care/UploadSection/Upload";
import Detail from "../screens/Care/DetailSection/Detail";
import Care from "../screens/Care/Care";
import MedicalIndex from "../screens/MedicalSummarizer/Index";
import MedicalSummarizer from "../screens/MedicalSummarizer/Chat/MedicalSummarizer";
import Report_reconcilieation from "../screens/AnamolyDetection/Report_reconciliation";
import SearchHighlighter from "../screens/Search_Highlighter/SearchHighlighter";
import HRAnalysis from "../screens/HR_Analysis/HRAnalysis";
import AnalysisResult from "../screens/HR_Analysis/AnalysisResult";
import DamageDetectionBox from "../screens/Damage_Detection/DamageDetectionBox";
import CyberSecurity from "../screens/Cybersecurity/cyberSecurity";
import LogsAnalysis from "../screens/Cybersecurity/LogsAnalysis";
import Aegon from "../screens/Aurigo/Aurigo";
import ChatHome from "../screens/Chat/ChatHome";
import ChatStart from "../screens/Chat/ChatStart";
import CFOChat from "../screens/Finance/CFODocIntelligence/Chat/CFOChat";
import CaptureImage from "../screens/CaptureImage/CaptureImage";
import LandingPage from "../screens/CaptureImage/LandingPage";
import DamageReports from "../screens/CaptureImage/DamageReports/DamageReports";
import ReportsList from "../screens/CaptureImage/DamageReports/ReportsList";
import ReportDetail1 from "../screens/CaptureImage/ImageUploadConfimation/ReportDetail1";
import ReportDetail2 from "../screens/CaptureImage/ImageUploadConfimation/ReportDetail2";
import TafTable from "../screens/TAF/TAF";
import TestLatency from "../screens/CaptureImage/TestLatency";

const Landing = React.lazy(() => import("../screens/Landing_page"));
const Chat = React.lazy(() => import("../screens/Chat"));
const Bullet = React.lazy(() => import("../screens/Bullet_Train"));
const Image = React.lazy(() => import("../screens/AI_Images"));
const Search = React.lazy(() => import("../screens/Search/Search"));
const Compare = React.lazy(() => import("../screens/Compare/Compare"));
const Settings = React.lazy(() => import("../screens/Setting/Index"));
const PathologyViewer = React.lazy(() => import("../screens/Pathology/ReportViewer/ReportViewer"));
const PathologyCompareDetail = React.lazy(() => import("../screens/Pathology/ReportViewer/Compare/CompareDetail"));
const CFOUpload = React.lazy(() => import("../screens/Finance/CFODocIntelligence/Upload"));

const RouterConfig: FC = ({ auth }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loading, setLoading] = useState<boolean>(false);
    const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const classes = useStyles();

    const handleFetchPDF = () => {
        getAccessTokenSilently()
            ?.then((token) => {
                try {
                    if (token) {
                        setContext({
                            ...contextStore,
                            privileged_token: token,
                        });
                        setLoading(() => true);
                        axios
                            .create({
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json; charset=utf-8",
                                },
                            })
                            ?.get(Api?.getUserInfo)
                            ?.then(async (profile: any) => {
                                const excelList: any = await Api?.getTables().then((res) => res);
                                if (profile?.status === 200) {
                                    const pdfList: any = await Api?.getPdfs({
                                        user_id: profile?.data?.data?.[0]?.userId,
                                    }).then((res) => res);
                                    const pathalogyList: any = await Api?.getPathoNamespaces({
                                        user_id: profile?.data?.data?.[0]?.userId,
                                    }).then((res) => res);
                                    if (pdfList && excelList && pathalogyList) {
                                        let pdf: any;
                                        let excel: any;
                                        let pathalogy: any;
                                        let selectedPdf: any;
                                        let selectedPathalogy: any;
                                        let userData: any;
                                        userData = profile?.data?.data?.[0];
                                        if (pdfList?.status === 200 && pdfList?.data?.length > 0) {
                                            pdf = { pdfList: pdfList?.data?.sort() };
                                            selectedPdf = pdfList?.data?.sort()[0]?.file_name;
                                        } else {
                                            pdf = {};
                                        }
                                        if (excelList?.status === 200) {
                                            excel = { excelList: excelList?.data };
                                        } else {
                                            excel = {};
                                        }
                                        if (pathalogyList?.status === 200) {
                                            pathalogy = { pathalogyList: pathalogyList?.data?.sort() };
                                            selectedPathalogy = pathalogyList?.data?.sort()[0];
                                        } else {
                                            pathalogy = {};
                                        }
                                        setContext({
                                            ...contextStore,
                                            ...pdf,
                                            ...excel,
                                            ...pathalogy,
                                            profile: userData,
                                            loading: false,
                                            temparary: { ...contextStore?.temparary, selectedPdf, selectedPathalogy },
                                        });
                                    }
                                    setLoading(() => false);
                                } else {
                                    logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
                                }
                            })
                            ?.catch((err) => {
                                setLoading(() => false);
                                logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "error",
                                        message: "Please clear the cache and retry for better experience.",
                                    },
                                });
                            });
                    }
                } catch (error) {
                    console.log(error);
                }
            })
            ?.catch((err) => {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Error occured while authenticating the user. Please Try again.",
                    },
                });
            });
    };

    useEffect(() => {
        if (isAuthenticated) handleFetchPDF();
        setAuthenticated(isAuthenticated);
    }, [isAuthenticated]);

    return (
        <Router>
            {!authenticated && (
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <Login />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/capture"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <CaptureImage />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/landingWebcam"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <LandingPage />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/testLatency"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <TestLatency />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    {/* <Route
                        path="/report_detail_1"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <ReportDetail1 />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/report_detail_2"
                        element={
                            <PublicRoute>
                                <Suspense>
                                    <ReportDetail2 />
                                </Suspense>
                            </PublicRoute>
                        }
                    /> */}
                </Routes>
            )}

            {authenticated && (
                <Box className={classes?.container}>
                    {authenticated &&
                    user?.email_verified &&
                    !window.location.href.includes("capture") &&
                    !window.location.href.includes("landingWebcam") &&
                    !window.location.href.includes("damageReports") ? (
                        <AppBar />
                    ) : null}
                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <RestrictedRoute>
                                    <Suspense fallback={<Fallback />}>
                                        <ChatRoutes />
                                    </Suspense>
                                </RestrictedRoute>
                            }
                        />
                    </Routes>
                    <DocumentLoadingModal loading={loading} />
                </Box>
            )}
        </Router>
    );
};

const useStyles = makeStyles({
    container: {
        height: "100vh",
        background: "#FEFDFD",
        display: "flex",
        flexDirection: "column",
    },
    subContainer: {
        display: "flex",
        flex: 1,
    },
});

const ChatRoutes = () => {
    const classes = useStyles();
    return (
        <Box className={classes?.subContainer}>
            <SideBar />
            <Routes>
                {[
                    { redirect: "/chat", element: Chat },
                    { redirect: "/bullet", element: Bullet },
                    { redirect: "/image", element: Image },
                    { redirect: "/search", element: Search },
                    { redirect: "/compare", element: Compare },
                    { redirect: "/summarize", element: Summarize },
                    { redirect: "/setting", element: Settings },
                    { redirect: "/pricing-calculator", element: Calculator },
                    { redirect: "/anamoly_detection", element: AnamolyDetection },
                    { redirect: "/invoice_reconciliation", element: Report_reconcilieation },
                    { redirect: "/restaurant_bot", element: RestaurantBot },
                    { redirect: "/claims", element: Claims },
                    { redirect: "/med", element: MedicalIndex },
                    { redirect: "/med_summary", element: MedicalSummarizer },
                    { redirect: "/search_highlight", element: SearchHighlighter },
                    { redirect: "/hr_analysis", element: HRAnalysis },
                    { redirect: "/aegon", element: Aegon },
                    { redirect: "/chat_home", element: ChatHome },
                    { redirect: "/chat_start", element: ChatStart },
                    { redirect: "/cfo_doc_intel", element: CFOUpload },
                    { redirect: "/cfo_chat", element: CFOChat },
                    { redirect: "/damageReports", element: ReportsList },
                    // { redirect: "/report_detail_1", element: ReportDetail1 },
                    { redirect: "/report_detail_2", element: ReportDetail2 },
                    { redirect: "/taf", element: TafTable },
                ]?.map((elem) => (
                    <Route
                        key={elem?.redirect}
                        path={elem?.redirect}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <elem.element />
                            </Suspense>
                        }
                    />
                ))}
                {/* <Route
                    key={"claims/upload"}
                    path={"/claims/upload"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <UploadDetail />
                        </Suspense>
                    }
                /> */}

                <Route
                    key={"care"}
                    path={"/care"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Care />
                        </Suspense>
                    }
                />
                <Route
                    key={"taf"}
                    path={"/taf"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <TafTable />
                        </Suspense>
                    }
                />
                <Route
                    key={"care/upload"}
                    path={"/care/upload"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Upload />
                        </Suspense>
                    }
                />

                <Route
                    key={"care/detail"}
                    path={"/care/detail"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Detail />
                        </Suspense>
                    }
                />

                <Route
                    key={"claims/summary"}
                    path={"/claims/summary"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Summary />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/new_project"}
                    path={"/claims/new_project"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <CreateProject />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/reports"}
                    path={"/claims/reports"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ViewProject />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/reference"}
                    path={"/claims/reference"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <References />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/comparison"}
                    path={"/claims/comparison"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Comparison />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/chat"}
                    path={"/claims/chat"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ClaimChatBox />
                        </Suspense>
                    }
                />
                <Route
                    key={"claims/tables"}
                    path={"/claims/tables"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Tables />
                        </Suspense>
                    }
                />

                <Route key={"chat_history"} path={"/chat_history"}>
                    <Route
                        key={"main"}
                        path={"main"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <ChatHistory />
                            </Suspense>
                        }
                    ></Route>
                    <Route key={"new_chat"} path={"new_chat"} element={<NewChat backRoute={true} />}></Route>
                </Route>
                <Route
                    path="/"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <OverViewPage />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    path="/cybersecurity"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <CyberSecurity />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    path="/cybersecurity/analysis"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <LogsAnalysis />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    path="/introduction"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <Intro />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    path="/landing"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <Landing />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    path="/test"
                    element={
                        <RestrictedRoute>
                            <Suspense fallback={<Fallback />}>
                                <Test />
                            </Suspense>
                        </RestrictedRoute>
                    }
                />
                <Route
                    key={"compare_pdf"}
                    path={"/compare_pdf"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ViewMultiplePDF />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"alert_generation"}
                    path={"/alert_generation"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <AlertGeneration />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"damage_detection"}
                    path={"/damage_detection"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <DamageDetectionBox />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"finance"}
                    path={"/finance"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Finance />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"finance/trial_balance"}
                    path={"/finance/trial_balance"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <TrialBalance />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"finance/income_statement"}
                    path={"/finance/income_statement"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <TrialBalance />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"sophie"}
                    path={"/sophie"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Bot />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"variance_analysis"}
                    path={"/variance_analysis"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ReportGeneration />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"report_generation/seen"}
                    path={"/report_generation/seen"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <SeeReport />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"variance_generation"}
                    path={"/variance_generation"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ChatBox />
                        </Suspense>
                    }
                ></Route>

                <Route
                    key={"pathology"}
                    path={"/pathology"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <Pathology />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"reportGeneration"}
                    path={"/report_generation"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ReportGeneration />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"damageReports"}
                    path="/damageReports"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ReportsList />
                        </Suspense>
                    }
                ></Route>
                {/* <Route
                    key={"report_detail_1"}
                    path="/report_detail_1"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ReportDetail1 />
                        </Suspense>
                    }
                ></Route> */}
                <Route
                    key={"report_detail_2"}
                    path="/report_detail_2"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <ReportDetail2 />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"pathology/viewer"}
                    path={"/pathology/viewer"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <PathologyViewer />
                        </Suspense>
                    }
                ></Route>
                <Route
                    key={"pathology/compare"}
                    path={"/pathology/compare"}
                    element={
                        <Suspense fallback={<Fallback />}>
                            <PathologyCompareDetail />
                        </Suspense>
                    }
                ></Route>

                <Route key={"data_source"} path={"/data_source"}>
                    <Route
                        key={"main"}
                        path={"main"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <DataSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_source"}
                        path={"add_source"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AddSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_source/mongoDB"}
                        path={"add_source/mongoDB"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AddMongoSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_source/youtube"}
                        path={"add_source/youtube"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AddYoutubeSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_source/googleSheets"}
                        path={"add_source/googleSheets"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AddGoogleSheetsSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_source/googleDocs"}
                        path={"add_source/googleDocs"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AddGoogleDocsSource />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"add_detail"}
                        path={"add_detail"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <DetailConfirrm />
                            </Suspense>
                        }
                    ></Route>
                </Route>

                <Route
                    path="*"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <PageNotFound />
                        </Suspense>
                    }
                />
                <Route path="/analysis_result" element={<AnalysisResult />} />
            </Routes>
        </Box>
    );
};

export default RouterConfig;
