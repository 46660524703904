import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import AttachmentIcon from "../../components/Common/svgLogs/AttachmentIcon";
import MongoDBColorIcon from "../../components/Common/svgLogs/MongoDBColorIcon";
import SqlIcon from "../../components/Common/svgLogs/SqlIcon";
import PostgresIcon from "../../components/Common/svgLogs/PostgresIcon";
import YoutubeIcon from "../../components/Common/svgLogs/YoutubeIcon";
import GoogleDocsIcon from "../../components/Common/svgLogs/GoogleDocsIcon";
import GoogleDriveIcon from "../../components/Common/svgLogs/GoogleDriveIcon";
import GoogleMailIcon from "../../components/Common/svgLogs/GoogleMailIcon";
import Upcoming1Icon from "../../components/Common/svgLogs/Upcoming1Icon";
import EllipseIcon from "../../components/Common/svgLogs/EllipseIcon";
import Upcoming2Icon from "../../components/Common/svgLogs/Upcoming2Icon";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import { Check, Close, Translate } from "@mui/icons-material";
import { Api } from "../../apis";
import GoogleSheetsIcon from "../../components/Common/svgLogs/GoogleSheetsIcon";
import PdfModal from "./PdfModal";
import UploadSocket from "./utils/UploadSocket";
import UnprocessedPdf from "./UnprocessedPdf";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import SearchIcon from "../../components/Common/svgLogs/SearchIcon";

const AddSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [pdfList, setPdfList] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState<any>({
        start_page: 1,
        end_page: "",
    });
    const [pageCount, setPageCount] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [pdfLoadings, setPdfLoadings] = useState<boolean>(false);
    const theme = useTheme();
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));
    const [msg, socketHooks, msgRef] = UploadSocket();
    const [translateAnchorEl, setTranslateAnchorEl] = useState<null | HTMLElement>(null);
    const translateOpen = Boolean(translateAnchorEl);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("English");

    const styles = () => {
        if (smd) {
            return {
                title: {
                    pl: 5,
                    gap: 1,
                },
                uploadBox1: {
                    flexDirection: "column",
                    gap: 3,
                },
                uploadBox2: {
                    width: "auto",
                },
                uploadBox3: {
                    height: "18rem",
                },
                upcomingBox: {
                    width: "9rem",
                    gap: 2,
                    pl: 0,
                    p: 1,
                },
                upcomingBoxMain: {
                    gap: 3,
                },
            };
        }
        return {};
    };

    const handleUpload = (file: any) => {
        console.log(file);
        setPdfLoadings(true);
        const formData = new FormData();
        if (file?.type === "application/pdf") {
            setLoading(() => true);
            const language = selectedLanguage === "हिंदी" ? "Hindi" : selectedLanguage;
            const userId: string = contextStore?.profile?.userId;
            formData.append("pdf_file", file);
            formData.append("page_start", pageNumber?.start_page || 1);
            formData.append("page_end", pageNumber?.end_page || 1);
            formData.append("user_id", userId);
            // formData.append("lang", language);
            Api?.uploadPdf(formData)?.then((res: any) => {
                if (res?.status === 202) {
                    // console.log({ res });
                    if (res?.data?.document_id === null) {
                        setContext({
                            ...contextStore,
                            source_file: undefined,
                            snackState: {
                                open: true,
                                errorType: "error",
                                message: `File already exists`,
                            },
                        });
                    } else {
                        setSelectedFile(() => null);
                        handleFetchPdf();
                        setContext({
                            ...contextStore,
                            source_file: undefined,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: `Upload Process Start For ${file?.name}`,
                            },
                        });
                    }
                    // navigate("/data_source/main");
                }
                setLoading(() => false);
            });
        }
        if (
            ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"]?.includes(file?.type) ||
            file?.name?.endsWith(".xlsx") ||
            file?.name?.endsWith(".xls")
        ) {
            formData.append("file", file);
            formData.append("user_id", contextStore?.profile?.userId);
            setLoading(() => true);
            Api?.uploadESGexcel(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    setSelectedFile(() => null);
                    handleFetchExcel();
                    setContext({
                        ...contextStore,
                        source_file: undefined,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "File Uploaded Successful",
                        },
                    });
                    navigate("/data_source/main");
                } else {
                    setContext({
                        ...contextStore,
                        source_file: undefined,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Something Went Wrong",
                        },
                    });
                }
                setLoading(() => false);
            });
        }
    };

    console.log({ contextStore });

    const handleFetchPdf = () => {
        Api?.getPdfs({ user_id: contextStore?.profile?.userId })?.then((res: any) => {
            if (res?.status === 200) {
                setPdfLoadings(false);
                setContext({ ...contextStore, pdfList: res?.data?.sort() });
                setPdfList(res?.data?.filter((pdf: any) => !pdf?.isprocessed));
            }
        });
    };

    const handleFetchExcel = () => {
        Api?.getTables()?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, excelList: res?.data });
            }
        });
    };

    const handlePdfModalClose = () => {
        setOpen(false);
        setPageNumber({
            start_page: 1,
            end_page: "",
        });
    };
    const handleInputDetails = (e: any) => {
        setPageNumber((prevState: any) => ({ ...prevState, [e.target.name]: e.target.value }));
    };
    const handlePdfSave = (e: any) => {
        setOpen(false);
        handleUpload(selectedFile);
    };
    useEffect(() => {
        if (selectedFile?.type?.includes("pdf")) {
            const reader: any = new FileReader();
            reader.readAsBinaryString(selectedFile);
            reader.onloadend = () => {
                const count = reader?.result?.match(/\/Type[\s]*\/Page[^s]/g)?.length;
                setPageCount(count);
            };
        }
    }, [selectedFile]);
    useEffect(() => {
        setPdfList(contextStore?.pdfList?.filter((pdf: any) => !pdf?.isprocessed));
    }, [contextStore?.pdfList]);

    return (
        <>
            <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, ...styles()?.title }}>
                    <IconButton onClick={() => navigate(contextStore?.previousRoute || "/data_source/main")}>
                        <LessThanIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight={600}>
                        Add Data Source
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", px: 1.5, gap: 8, ...styles()?.uploadBox1 }}>
                    <Box sx={{ width: "58%", ...styles()?.uploadBox2 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography variant="subtitle2">Upload Documents</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography sx={{ width: "fit-content", marginRight: "0.95rem", color: "#747474" }}>
                                    Document Language
                                </Typography>
                                <IconButton
                                    sx={{
                                        width: "fit-content",
                                        border: "1px solid #747474",
                                        borderRadius: "15px",
                                        paddingX: "10px",
                                        color: "#747474",
                                        fontSize: "16px",
                                        fontFamily: "Outfit",
                                    }}
                                    aria-label="Translate"
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                        setTranslateAnchorEl(event.currentTarget)
                                    }
                                >
                                    <Translate sx={{ fontSize: "16px", marginRight: "0.55rem" }} />
                                    {selectedLanguage}
                                </IconButton>
                                <Menu
                                    id="translate-menu"
                                    anchorEl={translateAnchorEl}
                                    open={translateOpen}
                                    onClose={() => setTranslateAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    MenuListProps={{
                                        "aria-labelledby": "translate-button",
                                        sx: {
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1.4,
                                            py: 1,
                                            px: 2,
                                        },
                                    }}
                                >
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography sx={{ fontSize: "1.05rem" }}>Languages</Typography>
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 0.5,
                                                fontSize: "0.75rem",
                                            }}
                                        >
                                            <SearchIcon /> Search
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ borderColor: "#eee" }} />
                                    <Typography fontSize={"0.8rem"} color={"#6B6B6B"}>
                                        Choose the language for response
                                    </Typography>
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, width: "14rem" }}>
                                        {["English", "Spanish", "German", "हिंदी", "French"]?.map((item: string) => (
                                            <MenuItem
                                                onClick={() => setSelectedLanguage(item)}
                                                sx={{
                                                    border: "1px solid #EEE",
                                                    borderRadius: "5px",
                                                    paddingX: "10px",
                                                    cursor: "pointer",
                                                    background: selectedLanguage === item ? "#e3f4ff" : "#fff",
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Box>
                                </Menu>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                border: "1px dashed #007BFF",
                                borderRadius: "14px",
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                position: "relative",
                                transition: "ease-in-out 550ms",
                                ...styles()?.uploadBox3,
                            }}
                            // height={pdfList?.length > 0 ? "17vh" : "58vh"}
                            height={"58vh"}
                            component={"label"}
                            onDrop={(e: any) => {
                                e.preventDefault();
                                setSelectedFile(e?.dataTransfer?.files[0]);
                            }}
                            onDragOver={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <input
                                hidden
                                type="file"
                                accept=".pdf, .xls, .xlsx, .csv"
                                onChange={(e: { target: { files: any; value: any } }) => {
                                    setSelectedFile(e?.target?.files[0]);
                                    setOpen(true);
                                    e.target.value = "";
                                }}
                            />
                            <Box
                                sx={{ display: "flex", alignItems: "center", gap: 3 }}
                                flexDirection={pdfList?.length ? "row" : "column"}
                            >
                                <UploadBoxIcon />
                                <Box>
                                    <Typography variant="subtitle2">
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                    {!selectedFile && (
                                        <Typography variant="caption">Supported pdf, xls, xlsx or csv only</Typography>
                                    )}
                                    {selectedFile && <Typography variant="caption">{selectedFile?.name}</Typography>}
                                </Box>
                            </Box>
                            {selectedFile && !selectedFile?.type?.includes("pdf") ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 2,
                                        alignItems: "center",
                                        position: "absolute",
                                        bottom: "2rem",
                                        right: "0%",
                                        width: "100%",
                                        justifyContent: "space-evenly",
                                        px: 4,
                                    }}
                                >
                                    <Typography title={selectedFile?.name} noWrap>
                                        {selectedFile?.name}
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Button
                                            sx={{
                                                textTransform: "none",
                                                px: 2,
                                                borderRadius: "7.31px",
                                            }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleUpload(selectedFile)}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    style={{ width: "20px", height: "20px" }}
                                                />
                                            ) : (
                                                // <Check sx={{ color: "#007BFF" }} />

                                                "Upload"
                                            )}
                                        </Button>
                                        <IconButton onClick={() => setSelectedFile(() => null)}>
                                            <Close color="error" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box sx={{ width: "40%", ...styles()?.uploadBox2 }}>
                        <Typography variant="subtitle2">Other Source Types</Typography>
                        <Box sx={{ height: "45vh", overflowY: "auto" }}>
                            <Box
                                sx={{ mt: 3, display: "flex", gap: 5, flexWrap: "wrap", ...styles()?.upcomingBoxMain }}
                            >
                                {[
                                    // { title: "Website", icon: AttachmentIcon, key: "website" },
                                    { title: "MongoDB", icon: MongoDBColorIcon, key: "mongoDB" },
                                    // { title: "SQL", icon: SqlIcon, key: "sql" },
                                    // { title: "PostgresSQL", icon: PostgresIcon, key: "postgresql" },
                                    // { title: "Google Docs", icon: GoogleDocsIcon, key: "googleDocs" },
                                    { title: "Youtube", icon: YoutubeIcon, key: "youtube" },
                                    { title: "Google Sheets", icon: GoogleSheetsIcon, key: "googleSheets" },
                                ]?.map((elem) => (
                                    <Box
                                        onClick={(e) => {
                                            navigate(`/data_source/add_source/${elem?.key}`);
                                            setContext({ ...contextStore, active_data_source: elem?.key });
                                        }}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            boxShadow: "0px 4px 8px 0px #AAAAAA1A",
                                            width: "13rem",
                                            height: "4.5rem",
                                            borderRadius: "14px",
                                            gap: 3,
                                            pl: 4,
                                        }}
                                    >
                                        <elem.icon />
                                        <Typography variant="subtitle2">{elem?.title}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Typography variant="subtitle2">Upcoming</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 4, mt: 2 }}>
                                <GoogleDriveIcon />
                                <GoogleMailIcon />
                                <Upcoming1Icon />
                                <EllipseIcon />
                                <Upcoming2Icon />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {selectedFile && selectedFile?.type?.includes("pdf") && (
                <PdfModal
                    open={open}
                    handleClose={handlePdfModalClose}
                    selectedFile={selectedFile}
                    handlePdfSave={handlePdfSave}
                    handleInputDetails={handleInputDetails}
                />
            )}
        </>
    );
};

export default AddSource;
