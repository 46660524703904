import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LogoXIcon from "../../../components/Common/svgLogs/LogoXIcon";
import ProfileAvatar from "../../../components/Common/svgLogs/ProfileAvatar";
import Typing from "../../Chat/Effects/Typing";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageBox = ({
    elem,
    lastElement,
    msgList,
    setMsgList,
    scrollRef,
}: {
    elem: any;
    lastElement: boolean;
    msgList: any[];
    setMsgList: any;
    scrollRef: any;
}) => {
    const [message, setMessage] = useState<any>("");
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        if (typeof elem?.msg === "string" && lastElement && elem?.msg?.length > 0) {
            if (index < elem?.msg?.split(" ")?.length) {
                setTimeout(() => {
                    setMessage((prev: string) => prev + elem?.msg?.split(" ")[index] + " ");
                    setIndex((prev) => prev + 1);
                    scrollRef?.current?.lastElementChild?.scrollIntoView(false);
                }, 50);
            }
        } else {
            setMessage(elem?.msg);
        }
    }, [elem?.msg, index]);

    return (
        <Stack width={"100%"}>
            {elem?.in && (
                <Stack
                    direction={"row"}
                    gap={1}
                    sx={{ background: "#fff", border: "1px solid #d9d9d9", p: 1.5, borderRadius: "10px" }}
                >
                    <Box pt={"4px"}>
                        <LogoXIcon />
                    </Box>
                    {elem?.load ? (
                        <Typing
                            onClick={() =>
                                setMsgList(
                                    (prev: any) =>
                                        prev?.map((item: any) => (item?.load ? { ...item, load: false } : item))
                                )
                            }
                        />
                    ) : (
                        <Typography
                            sx={{
                                color: elem?.error ? "red" : "inherit",
                                fontSize: "0.9rem",
                                "& *": { fontSize: "0.9rem" },
                                whiteSpace: "pre-line",
                                p: 2,
                            }}
                        >
                            {typeof message === "string" ? (
                                <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
                            ) : (
                                message
                            )}
                        </Typography>
                    )}
                </Stack>
            )}
            {elem?.out && (
                <Stack direction={"row"} gap={1}>
                    <Box>
                        <ProfileAvatar />
                    </Box>
                    <Typography>{message}</Typography>
                </Stack>
            )}
        </Stack>
    );
};

export default MessageBox;
